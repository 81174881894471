import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <h1 className="text-center">404: Not Found</h1>
    <span className="text-center block">You just hit a route that doesn&#39;t exist... the sadness.</span>
    <span className="text-center block"><Link to="/" className="mr-5 text-baseline text-blue-600 hover:text-blue-00 no-underline hover:underline ">Get back to safety</Link></span>
  </Layout>
)

export default NotFoundPage
